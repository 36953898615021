const honeyPotName = 'p_0fb67a0e1d7d47c0b12ec7c0af02dd16';

const showMessage = (message, status, form) => {
    const {identifier} = form.dataset;
    const messageEl = form.querySelector(identifier ? `[id="id__kui_message_${identifier}"]` : '[id="id__kui_message"]');
    messageEl.classList.remove('kuiembed-message--error', 'kuiembed-message--success');
    messageEl.classList.add(`kuiembed-message--${status}`);
    messageEl.textContent = message;
}

const resetInvalidFields = (form) => {
    const inputs = Array.from(form.querySelectorAll('input'));
    inputs.forEach(el => {
        el.removeAttribute('aria-invalid');
        el.removeAttribute('aria-describedby');
    });
}

const setInvalidFields = (name, form) => {
    const {identifier} = form.dataset;
    const el = form.querySelector(`input[name="${name}"]`);
    el.setAttribute('aria-invalid', true);
    el.setAttribute('aria-describedby', identifier ? `id__kui_message_${identifier}` : 'id__kui_message');
}

const resetInputFields = form => {
    form.querySelector('input[name="new_email"]').value = '';
    form.querySelector('input[name="new_name"]').value = '';
}

const generateUniqueIdentifers = form => {
    // eslint-disable-next-line max-len
    // We append ids programmatically to allow multiple instances of this form on one page
    const identifier = (Math.random() + 1).toString(36).substring(7);
    form.id = `id__kui_form_${identifier}`;
    form.setAttribute('data-identifier', identifier);
    const formEls = Array.from(form.querySelectorAll('[data-kui-element="true"]'));
    formEls.forEach(el => {
        const id = `id__kui_${el.name || 'message'}_${identifier}`
        el.id = id;
        const label = form.querySelector(`label[data-kui-name="${el.name}"]`);
        if (label) label.setAttribute('for', id);
    });

}

const postForm = async (action, body, form) => {
    try {
        const response = await fetch(action, {
            method: 'POST',
            headers: {
                Accept: 'roast/mixed',
                'X-Requested-With': 'XMLHttpRequest',
                'X-Request-Type': 'subscribe'
            },
            body
        });

        const res = await response.json();
        const data = JSON.parse(res.json);
        showMessage(data.message, data.status, form);
        if (data.status === "success") {
            resetInputFields(form);
        } else {
            setInvalidFields(data.error_param, form);
        }
    } catch (error) {
        console.error('Error:', error);
        showMessage(error.message, error.status, form);
    }
}

const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    resetInvalidFields(form);
    const {action} = form;
    const formData = new FormData(form);

    if (form.querySelector(`input[name="${honeyPotName}"]`).value !== '') {
        showMessage('Cannot process request!', 'error', form);
        return;
    }

    postForm(action, formData, form);
}

const bind = () => {
    const forms = Array.from(document.querySelectorAll('form[data-kui-form="true"]'));
    const isSingularForm = forms.length === 1;
    forms.forEach(form => {
        if (!isSingularForm) generateUniqueIdentifers(form);
        form.addEventListener('submit', handleSubmit);
    });
}


window.addEventListener('load', bind);
